import React, { useState } from "react";
import Headbar from "./components/Headbar";
import { Box } from "@mui/material";
import Practices from "./components/Practices";

const PracticeModule = () => {

  const [searchTerm, setSearchTerm] = useState('');
  return <Box>
    <Headbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    <Practices searchTerm={searchTerm} />
  </Box>;
};

export default PracticeModule;
