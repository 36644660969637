import React from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LineChart from '../../../components/charts/Line'
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import CustomDescriptionParser from 'components/DescriptionParser';


const Statistics = ({ statsData = {}, duration }) => {

    const { data: { totalUsers = 0, paidUsers = 0, freeUsers, totalEarning = 0, reportData = [] } } = statsData;

    const monthlyPaidUser = reportData ? reportData.map((item) => item.paid) : [];
    const yearlyPaidUser = reportData ? reportData[0] : [];
    const monthlyFreeUser = reportData ? reportData.map((item) => item.free) : [];
    const yearlyFreeUser = reportData ? reportData[1] : [];

    const statisticsData = statsData ? [{
        Icon: PeopleOutlineIcon,
        title: 'Total Number Of Users',
        count: totalUsers, duration: duration ? `Last ${duration}` : 'All Time', percent: '25',
    }, {
        Icon: PeopleOutlineIcon,
        title: 'Free Users',
        count: freeUsers, duration: duration ? `Last ${duration}` : 'All Time', percent: '40'
    }, {
        Icon: PeopleOutlineIcon,
        title: 'Paid Users',
        count: paidUsers, duration: duration ? `Last ${duration}` : 'All Time', percent: '5'
    }, {
        Icon: PaidRoundedIcon,
        title: 'Total Earnings',
        count: totalEarning, duration: duration ? `Last ${duration}` : 'All Time', percent: '20'
    }] : [];


    return (
        <Box mt={3}>
            <Grid container spacing={3}>
                {statisticsData.map(({ title, Icon, count, duration, percent }, i) => {
                    return (
                        <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                            <Card sx={{ padding: '16px', borderRadius: "20px" }}
                            >
                                <CardContent style={{ padding: "0px" }}>
                                    <Box marginTop={"8px"}>
                                        <Box
                                            display="flex"
                                            width={1}
                                            alignItems="start"
                                            gap={"8px"}
                                        >
                                            <Icon />
                                            {/* <Typography variant="body1" color="#51566C" fontWeight={500}>
                                                {title}
                                            </Typography> */}
                                            <CustomDescriptionParser description={title} color="#51566C" fontWeight={500} limit={2} lineHeight={1.6} />
                                        </Box>
                                        <Box
                                            display="flex"
                                            gap={2}
                                            mt={0.5}
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Typography variant='h3' fontWeight={600}>{count}</Typography>
                                            <LineChart />
                                        </Box>
                                        <Box display='flex' gap={2} justifyContent='space-between' alignItems='center'>
                                            <Typography textTransform='capitalize' variant="body1" color="#51566C" fontWeight={500}>
                                                {duration}
                                            </Typography>
                                            {
                                                duration !== 'All Time' && (
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        gap="5px"
                                                        bgcolor="#00997E29"
                                                        borderRadius="90px"
                                                        padding="4px"
                                                        color={"#00997E"}
                                                    >
                                                        <TrendingUpIcon />
                                                        <Typography variant='body2' fontSize='11px' fontWeight={600}>{percent}%</Typography>

                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                    )
                })}
            </Grid>
        </Box>
    )
}

export default Statistics