import React from "react";
import AddJob from "./components/AddJob";
import Jobs from "./components/Jobs";

const CareersModule = () => {

  return (
    <div>
      <AddJob />
      <Jobs />
    </div>
  );
};

export default CareersModule;
