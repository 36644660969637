import React from "react";
import { AppBar, Toolbar, Box, IconButton, Typography, Menu, MenuItem, ListItemText } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const TopNav = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentMenu, setCurrentMenu] = React.useState(null);

    const handleMenuOpen = (menu, event) => {
        setAnchorEl(event.currentTarget);
        setCurrentMenu(menu);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentMenu(null);
    };

    const handleNavigation = (path) => {
        navigate(path);
        handleMenuClose();
    };

    const menuItems = {
        contentManager: [
            { text: "After the Whistle", path: "/whistle" },
            { text: "Blogs", path: "/blogs" },
            { text: "BookClub", path: "/bookclub" },
            { text: "Clips", path: "/clips" },
            { text: "A-Z", path: "/library" },
            { text: "BluePrint", path: "/blueprint" },
            { text: "Lounge", path: "/lounge" },
            { text: "Tape", path: "/tape" },
            { text: "Practice", path: "/practice" },
            { text: "Sessions", path: "/events" },
            { text: "Careers", path: "/careers" },
            { text: "Support", path: "/support-tickets" },
        ],
        manageUsers: [
            { text: "User List", path: "/user/list" },
            { text: "Add User", path: "/user/add" },
            { text: "Reports", path: "/user/reports" },
        ],
        vorAmePlans: [
            { text: "Plans", path: "/plans" },
            { text: "Discounted Plans", path: "/promotions" },
            { text: "Discounted Promos", path: "/promos" },
        ],
        payments: [

        ]
    };

    return (
        <Box width={1} alignItems='center' display='flex' justifyContent='space-between'>
            <Box component='img' height='54px' width='55px' className="cursor-pointer" onClick={() => navigate('/')} src="/logos/Logo.png" alt="Logo" />

            <Box justifyContent='center' sx={{ display: "flex", gap: 3, flexGrow: 1 }}>
                <Box display='flex' className='cursor-pointer' onClick={() => handleNavigation("/")} gap='12px' alignItems='center'>
                    <img
                        src="/icons/home-smile.svg"
                        alt='dashboard'
                    />
                    <ListItemText primary='Dashboard' />

                </Box>
                <Box>
                    <Box display='flex' className='cursor-pointer' onClick={(e) => handleMenuOpen("contentManager", e)} gap='12px' alignItems='center'>
                        <img
                            src="/icons/diamond-01.svg"
                            alt='diamong'
                        />
                        <ListItemText primary='Manage Content' />
                        <Box component='img' width='20px' height='20px' src='/icons/create-down.svg' alt="more" />
                    </Box>
                    {currentMenu === "contentManager" && (
                        <Menu
                            id="content-manager"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            slotProps={{
                                paper: {
                                    sx: { maxWidth: '180px', width: '100%' },
                                }
                            }}
                        >
                            {menuItems.contentManager.map((item) => (
                                <MenuItem key={item.text} onClick={() => handleNavigation(item.path)}>
                                    <ListItemText primary={item.text} />
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                </Box>


                <Box>
                    <Box display='flex' className='cursor-pointer' onClick={(e) => handleMenuOpen("manageUsers", e)} gap='12px' alignItems='center'>
                        <img
                            src="/icons/users.svg"
                            alt='users'
                        />
                        <ListItemText primary='Manage Users' />
                        <Box component='img' width='20px' height='20px' src='/icons/create-down.svg' alt="more" />
                    </Box>

                    {currentMenu === "manageUsers" && (
                        <Menu
                            id="user-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            slotProps={{
                                paper: {
                                    sx: { maxWidth: '180px', width: '100%' },
                                }
                            }}
                        >
                            {menuItems.manageUsers.map((item) => (
                                <MenuItem key={item.text} onClick={() => handleNavigation(item.path)}>
                                    {item.text}
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                </Box>

                <Box>
                    <Box display='flex' className='cursor-pointer' onClick={(e) => handleMenuOpen("payments", e)} gap='12px' alignItems='center'>
                        <img
                            src="/icons/businessplan.svg"
                            alt='diamong'
                        />
                        <ListItemText primary='Payments' />
                        <Box component='img' width='20px' height='20px' src='/icons/create-down.svg' alt="more" />
                    </Box>
                    {menuItems.payments.length > 0 && currentMenu === "payments" && (
                        <Menu
                            id="paid-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {menuItems.payments.map((item) => (
                                <MenuItem key={item.text} onClick={() => handleNavigation(item.path)}>
                                    <ListItemText primary={item.text} />
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                </Box>



                {/* <Box>
                    <Typography
                        variant="button"
                        aria-controls="vorame-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleMenuOpen("vorAmePlans", e)}
                        style={{ cursor: "pointer" }}
                    >
                        <ListItemText primary='Payments' />
                    </Typography>
                    {currentMenu === "vorAmePlans" && (
                        <Menu
                            id="vorame-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {menuItems.vorAmePlans.map((item) => (
                                <MenuItem key={item.text} onClick={() => handleNavigation(item.path)}>
                                    {item.text}
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                </Box> */}

                <Box display='flex' className='cursor-pointer' onClick={(e) => handleMenuOpen("contentManager", e)} gap='12px' alignItems='center'>
                    <img
                        src="/icons//help.svg"
                        alt='faqs'
                    />
                    <ListItemText primary='FAQs' />
                </Box>
            </Box>



            {/* <IconButton onClick={() => handleNavigation("/careers")}>Careers</IconButton>
                <IconButton onClick={() => handleNavigation("/faqs")}>FAQs</IconButton> */}
            {/* 
            <Box>
                {children}
            </Box> */}
        </Box>
    );
};

export default TopNav;
