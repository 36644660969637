// Auth
export const AUTH = "auth";
export const LOGIN = `${AUTH}/login`;
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`;
export const RESET_PASSWORD = `${AUTH}/reset-password`;

// Blogs
export const BLOG = "blog";
export const GET_BLOGS = `${BLOG}/list`;
export const CREATE_BLOG = `${BLOG}/create`;
export const GET_SINGLE_BLOG = `${BLOG}/single-blog`;
export const UPDATE_BLOG = `${BLOG}/update`;
export const UPDATE_BLOG_STATUS = `${BLOG}/update-status`;
export const UPDATE_BLOG_FAVOURITE = `${BLOG}/update-favourite`;
export const DELETE_BLOG = `${BLOG}/delete`;

// Book club
export const BOOK_CLUB = "book-club";
export const GET_BOOK_CLUBS = `${BOOK_CLUB}/list`;
export const CREATE_BOOK_CLUB = `${BOOK_CLUB}/create`;
export const GET_SINGLE_BOOK_CLUB = `${BOOK_CLUB}/single-book`;
export const UPDATE_BOOK_CLUB = `${BOOK_CLUB}/update`;
export const UPDATE_BOOK_CLUB_STATUS = `${BOOK_CLUB}/update-status`;
export const UPDATE_BOOK_CLUB_FAVOURITE = `${BOOK_CLUB}/update-favourite`;
export const DELETE_BOOK_CLUB = `${BOOK_CLUB}/delete`;

// Clips
export const CLIP = "clip";
export const GET_CLIPS = `${CLIP}/list`;
export const CREATE_CLIP = `${CLIP}/create`;
export const GET_SINGLE_CLIP = `${CLIP}/single-clip`;
export const UPDATE_CLIP = `${CLIP}/update`;
export const UPDATE_CLIP_STATUS = `${CLIP}/update-status`;
export const UPDATE_CLIP_FAVOURITE = `${CLIP}/update-favourite`;
export const DELETE_CLIP = `${CLIP}/delete`;

// Library
export const LIBRARY = "library";
export const GET_LIBRARIES = `${LIBRARY}/list`;
export const CREATE_LIBRARY = `${LIBRARY}/create`;
export const GET_SINGLE_LIBRARY = `${LIBRARY}/single-library`;
export const UPDATE_LIBRARY = `${LIBRARY}/update`;
export const UPDATE_LIBRARY_STATUS = `${LIBRARY}/update-status`;
export const UPDATE_LIBRARY_FAVOURITE = `${LIBRARY}/update-favourite`;
export const DELETE_LIBRARY = `${LIBRARY}/delete`;

// Whistle
export const WHISTLE = "whistle";
export const GET_WHISTLES = `${WHISTLE}/list`;
export const CREATE_WHISTLE = `${WHISTLE}/create`;
export const GET_SINGLE_WHISTLE = `${WHISTLE}/single-whistle`;
export const UPDATE_WHISTLE = `${WHISTLE}/update`;
export const UPDATE_WHISTLE_STATUS = `${WHISTLE}/update-status`;
export const DELETE_WHISTLE = `${WHISTLE}/delete`;

// Blue Print
export const BLUE_PRINT = "print";
export const GET_BLUE_PRINTS = `${BLUE_PRINT}/list`;
export const CREATE_BLUE_PRINT = `${BLUE_PRINT}/create`;
export const GET_SINGLE_BLUE_PRINT = `${BLUE_PRINT}/single-print`;
export const UPDATE_BLUE_PRINT = `${BLUE_PRINT}/update`;
export const UPDATE_BLUE_PRINT_STATUS = `${BLUE_PRINT}/update-status`;
export const DELETE_BLUE_PRINT = `${BLUE_PRINT}/delete`;

export const PLAN = "plan/";
export const TAPE = "tape/";
export const USER = "user/";
export const PRACTICE = "practice/";
export const FAQ = "faq/";
export const PROMO = "promo-code/";
export const PROMOTION = "plan/promotion/";

// Lounge
export const LOUNGE = "lounge";
export const GET_LONGES = `${LOUNGE}/list`;
export const CREATE_LOUNGE = `${LOUNGE}/create`;
export const GET_SINGLE_LOUNGE = `${LOUNGE}/single-lounge`;
export const UPDATE_LOUNGE = `${LOUNGE}/update`;
export const UPDATE_LOUNGE_STATUS = `${LOUNGE}/update-status`;
export const DELETE_LOUNGE = `${LOUNGE}/delete`;

// Career

export const CAREER = "career";
export const CAREERS = "careers";
export const CAREER_APPLICANTS_KEY = `${CAREER}-applicants`;
export const CREATE_CAREER = `${CAREER}/create`;
export const ADD_CAREER_APPLICANT = `${CAREER}/applicant/`;
export const CAREER_LIST = `${CAREER}/list`;
export const GET_CAREER = `${CAREER}/`;
export const CAREER_APPLICANT = `${CAREER}/applicant/`;
export const CAREER_APPLICANTS = `${CAREER}/applicants/`;
export const UPDATE_CAREER_STATUS = `${CAREER}/status`;
export const DELETE_CAREER = `${CAREER}/delete`;

// Support

export const SUPPORT_TICKET = "support-ticket";
export const SUPPORT_TICKET_KEY = "support-tickets";
export const SUPPORT_TICKET_STATUS = "support-ticket/status/";
export const SUPPORT_TICKETS = "support-tickets";
export const SUPPORT_TICKET_LIST = `${SUPPORT_TICKET}/list`;

// Event

export const EVENT = "event";
export const EVENT_KEY = "events";
export const EVENT_STATUS = "event/status/";
export const EVENTS = "events";
export const CREATE_EVENT = `${EVENT}/create`;
export const EVENT_LIST = `${EVENT}/list`;
export const GET_EVENT = `${EVENT}/`;
export const EVENT_RESERVATION = `${EVENT}/reservation/`;
export const EVENT_RESERVATIONS = `${EVENT}/reservations/`;
export const UPDATE_EVENT_STATUS = `${EVENT}/status`;
export const DELETE_EVENT = `${EVENT}/delete`;

// Dashboard

export const DASHBOARD = 'dashboard'
export const DASHBOARD_STATS = 'dashboard/stats'
export const DASHBOARD_TOTAL_USERS = `${DASHBOARD}/total-user/`;
export const DASHBOARD_USERS = `${DASHBOARD}/users/`;
export const DASHBOARD_EARNING = `${DASHBOARD}/total-earning`;
export const DASHBOARD_EARNING_REPORT = `${DASHBOARD}/earning-report`;