import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { MenuItem, Select, Typography } from '@mui/material'
import useApiQuery from 'hooks/useApiQuery';
import { DASHBOARD_STATS } from 'services/constants';
import Loader from 'components/Loader';
import Error from 'components/Error';
import DashboardComponents from './components';

const Dashboard = () => {
    const [duration, setDuration] = useState('');

    const {
        isLoading,
        error,
        data: apiResponse,
    } = useApiQuery({ queryKey: [DASHBOARD_STATS, duration], url: DASHBOARD_STATS + (duration ? `/?duration=${duration}` : '') });

    const handleChange = (event) => {
        setDuration(event.target.value);
    };

    if (error) return <Error error={error} />
    if (isLoading) {
        return <Loader />
    }

    return (
        <div>
            <Box display='flex' justifyContent='space-between' alignItems='center' gap={2}>
                <Box>
                    <Typography variant='h3' fontWeight={600}>Dashboard</Typography>
                    <Typography variant="body1" color="#858688" >
                        Welcome to the Dashboard
                    </Typography>
                </Box>
                <Select
                    value={duration}
                    size='small'
                    sx={{ minWidth: 120 }}
                    placeholder='Select Duration'
                    onChange={handleChange}
                >
                    <MenuItem value=''>Select Duration</MenuItem>
                    <MenuItem value='month'>Monthly</MenuItem>
                    <MenuItem value='year'>Yearly</MenuItem>
                </Select>
            </Box>
            <DashboardComponents apiResponse={apiResponse} duration={duration} />
        </div>
    )
}

export default Dashboard

