import React, { useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ConfirmDialog } from "components";
import { useQueryClient } from "react-query";
import { PRACTICE } from "services/constants";
import { toast } from "react-toastify";
import CustomDialog from "components/Modal";
import UpdateStatusDialog from "components/StatusDialog/StatusDialog";
import useApiMutation from "hooks/useApiMutation";
import CreatePractice from "./CreatePractice";
import FixedBox from "components/FixedBox";
import CustomDescriptionParser from "components/DescriptionParser";
import { vorameColors } from "theme/constants";

const SinglePractice = (practiceData) => {
  const { _id, description, file, status } = practiceData;
  const queryClient = useQueryClient();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const { mutate, isLoading } = useApiMutation();

  const fetchPracticeList = () =>
    queryClient.invalidateQueries({ queryKey: "practices" });
  const handleSuccess = (message) => {
    toast.success(message);
    fetchPracticeList();
  };

  const handleDelete = () => {
    mutate(
      { method: "delete", url: PRACTICE + `delete/${_id}` },
      {
        onSuccess: ({ message }) => {
          handleSuccess(message);
          setOpenDeleteModal(false);
        },
      }
    );
  };

  const updateStatusHandler = (status) => {
    mutate(
      {
        method: "post",
        url: PRACTICE + "update-status",
        data: { id: _id, status },
      },
      {
        onSuccess: ({ message }) => {
          handleSuccess(message);
          setOpenStatusModal(false);
        },
      }
    );
  };

  return (
    <Box width='100%'>
      <ConfirmDialog
        title="Delete Practice ?"
        dialogContext="Are you sure to delete practice ?"
        open={openDeleteModal}
        isLoading={isLoading}
        setOpen={setOpenDeleteModal}
        onConfirm={handleDelete}
      />
      <CustomDialog
        title="Update Practice"
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
      >
        <CreatePractice practiceData={practiceData} setOpen={setOpenUpdateModal} />
      </CustomDialog>
      <UpdateStatusDialog
        open={openStatusModal}
        onClose={() => setOpenStatusModal(false)}
        onUpdate={updateStatusHandler}
        isLoading={isLoading}
        status={status}
      />
      <Box
        gap="20px"
        borderRadius="10px"
        bgcolor="white"
        boxShadow="0px 0px 34px 0px #2632381F"
        height="100%"
      >
        <Box mt="12px" p="12px">
          {
            file.length > 0 ? (
              <Box component='img' sx={{ objectFit: 'cover' }} src={file[0].url} maxHeight={227} minHeight={227} width={1} />
            ) : (
              <Box maxHeight={230} minHeight={232} width={1} />
            )
          }
          <Box display='flex' alignItems='center'>
            <FixedBox height="210px">
              <CustomDescriptionParser description={description} limit={8} color={vorameColors.lightSlateGrey} />
            </FixedBox>
            {/* <div
              color="#858688"
              className="custom-clamp-8"
              dangerouslySetInnerHTML={{ __html: description }}
            /> */}
            {/* <Button sx={{ color: '#222222', fontWeight: 400, mt: 0.3 }}>More</Button> */}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={2}
            alignItems="center"
          >
            <Button
              sx={{
                textTransform: "capitalize",
                bgcolor: "#2E8852",
                borderRadius: "17px",
                color: "white",
                fontWeight: 400,
                py: "1px",
                px: "6px",
                fontSize: "12px",
              }}
              onClick={() => setOpenStatusModal(true)}
            >
              {status}
            </Button>

            <Box display="flex" gap={2}>
              <Box
                component="img"
                height="20px"
                width="20px"
                color="red"
                src="/icons/edit.svg"
                onClick={() => setOpenUpdateModal(true)}
                alt="edit"
              />
              <Box
                component="img"
                height="20px"
                width="20px"
                onClick={() => setOpenDeleteModal(true)}
                src="/icons/trash.svg"
                alt="trash"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SinglePractice;
