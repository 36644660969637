import React, { useState } from "react";
import { Box, Typography, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import UpdateStatusDialog from "components/StatusDialog/StatusDialog";
import useApiMutation from "hooks/useApiMutation";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { USER } from "constants";
import { ConfirmDialog } from "components";

const SingleUser = (userData) => {
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useApiMutation();
  const fetchUserList = () =>
    queryClient.invalidateQueries({ queryKey: "users" });
  const { _id, firstName, lastName, email, createdAt, isActive, plan = {} } =
    userData || {};

  const { name = "-" } = plan || {};
  const handleDelete = () => {
    mutate(
      { method: "patch", url: USER + `/${_id}`, data: { deleted: true } },
      {
        onSuccess: ({ message }) => {
          handleSuccess(message);
          setOpenDeleteModal(false);
        },
      }
    );
  };

  const handleSuccess = (message) => {
    toast.success(message);
    fetchUserList();
  };

  const updateStatusHandler = (status) => {
    mutate(
      { method: "patch", url: USER + `/${_id}`, data: { isActive: status } },
      {
        onSuccess: ({ message }) => {
          handleSuccess(message);
          setOpenStatusModal(false);
        },
      }
    );
  };

  return (
    <>
      <UpdateStatusDialog
        open={openStatusModal}
        onClose={() => setOpenStatusModal(false)}
        onUpdate={updateStatusHandler}
        isLoading={isLoading}
        status={isActive}
      />
      <ConfirmDialog
        title="Delete User ?"
        dialogContext="Are you sure to delete user ?"
        open={openDeleteModal}
        isLoading={isLoading}
        setOpen={setOpenDeleteModal}
        onConfirm={handleDelete}
      />
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">{firstName || "-"}</TableCell>
        <TableCell align="center">{lastName || "-"}</TableCell>
        <TableCell align="center">{email}</TableCell>
        <TableCell align="center">{name}</TableCell>
        <TableCell align="center">
          {moment(createdAt).format("DD-MM-YYYY")}
        </TableCell>
        <TableCell align="center">
          <Box width="100%" display="flex" justifyContent="center">
            <Box
              display="flex"
              gap="4px"
              alignItems="center"
              bgcolor="#ECFDF3"
              borderRadius="16px"
              py="2px"
              px={1}
              justifyContent="center"
              maxWidth="63px"
            >
              <Box
                borderRadius="50%"
                component="span"
                display="inline-block"
                bgcolor="#12B76A"
                width="6px"
                height="6px"
              />
              <Typography
                color="#027A48"
                fontSize="12px"
                minWidth={4}
                onClick={() => setOpenStatusModal(true)}
                textTransform="capitalize"
                className="cursor-pointer"
                fontWeight={500}
              >
                {isActive}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box display="flex" gap={2}>
            {/* <Box
        component="img"
        height="20px"
        width="20px"
        color="red"
        src="/icons/edit.svg"
        alt="edit"
      /> */}
            <Box
              component="img"
              height="20px"
              onClick={() => setOpenDeleteModal(true)}
              width="20px"
              className="cursor-pointer"
              src="/icons/trash.svg"
              alt="trash"
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleUser;
