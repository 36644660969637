import React from "react";
import parse from "html-react-parser";
import { StyledDescription } from "./style";

const CustomDescriptionParser = ({ description, limit, color = null, fontWeight = 400, lineHeight = 1.9 }) => {
  return (
    <StyledDescription variant="body4" limitLines={limit} color={color} fontWeight={fontWeight} lineHeight={lineHeight}>
      {parse(description)}
    </StyledDescription>
  );
};

export default CustomDescriptionParser;
