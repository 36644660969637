import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ConfirmDialog } from "components";
import CustomDialog from "components/Modal";
import CreateJob from "./CreateJob";
import UpdateStatusDialog from "components/StatusDialog/StatusDialog";
import useApiMutation from "hooks/useApiMutation";
import { useQueryClient } from "react-query";
import { PROMOTION } from "services/constants";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const SingleJob = ({ _id, title, location, createdAt, lastDate }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useApiMutation();

  const fetchPromoList = () => queryClient.invalidateQueries({ queryKey: 'promotions' });

  const handleDelete = () => {
    mutate({ method: 'delete', url: PROMOTION }, {
      onSuccess: () => {
        setOpenDeleteModal(false);
        fetchPromoList();
      }
    });
  };

  const updateStatusHandler = (status) => {
    mutate({ url: PROMOTION, data: { status } });
  };

  return (
    <>
      <ConfirmDialog
        title="Delete Promotion"
        dialogContext="Are you sure to delete promotion ?"
        open={openDeleteModal}
        isLoading={isLoading}
        setOpen={setOpenDeleteModal}
        onConfirm={handleDelete}
      />
      <CustomDialog
        title="Update Promotion"
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
      >
        <CreateJob JobData={{}} setOpen={setOpenUpdateModal} />
      </CustomDialog>
      <UpdateStatusDialog
        open={openStatusModal}
        onClose={() => setOpenStatusModal(false)}
        onUpdate={updateStatusHandler}
        isLoading={false}
        status={'inactive'}
      />
      <Box
        gap={3}
        py={2}
        px={3}
        borderRadius="10px"
        bgcolor="white"
        className='cursor-pointer'
        boxShadow="0px 0px 34px 0px #2632381F"
        height="100%"
        onClick={() => navigate(`/job/${_id}`)}
      >
        <Box
          flexGrow={1}
          display="flex"
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent='space-between'
          gap="20px"
        >
          <Box>
            <Typography variant="body1" fontSize="10px" color="#858688">
              Job Title:
            </Typography>
            <Typography
              variant="body2"
              mt={1}
              color="#222222"
              fontSize="14px"
              fontWeight={600}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" fontSize="10px" color="#858688">
              Job Location:
            </Typography>
            <Typography
              variant="body2"
              mt={1}
              color="#222222"
              fontSize="14px"
              fontWeight={600}
            >
              {location}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" fontSize="10px" color="#858688">
              Date Posted:
            </Typography>
            <Typography
              variant="body2"
              mt={1}
              color="#222222"
              fontSize="14px"
              fontWeight={600}
            >
              {moment(createdAt).format('DD-MM-YYYY')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" fontSize="10px" color="#858688">
              End Date:
            </Typography>
            <Typography
              variant="body2"
              mt={1}
              color="#222222"
              fontSize="14px"
              fontWeight={600}
            >
              {moment(lastDate).format('DD-MM-YYYY')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SingleJob;
