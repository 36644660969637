import React from "react";
import { Stack } from "@mui/material";
import NoData from "components/NoData";
import useApiQuery from "hooks/useApiQuery";
import { PROMOTION } from "services/constants";
import SinglePromotion from "../components/SinglePromotion";
import Loader from "components/Loader";
import Error from "components/Error";

const Promotions = ({ searchTerm }) => {
    const {
        isLoading,
        error,
        data: apiResponse,
    } = useApiQuery({ queryKey: ["promotions", searchTerm], url: PROMOTION + `list?searchTerm=${searchTerm}` });


    if (isLoading) return <Loader />
    if (error) return <Error error={error} />

    return (
        <div>
            {apiResponse &&
                (apiResponse.data.length > 0 ? (
                    <Stack mt='27.5px' direction='column' gap='21px'>
                        {
                            apiResponse.data.map((promotionData) => (
                                <SinglePromotion key={promotionData._id} {...promotionData} />
                            ))
                        }
                    </Stack>

                ) : (
                    <NoData />
                ))}
        </div>
    );
};

export default Promotions;
