import { useState } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, IconButton, Pagination, Typography } from "@mui/material";
import Headbar from "./Headbar";
import Reports from "./Reports";

const UserReports = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [limit, setLimit] = useState(9);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    return (
        <Box display='flex' gap={0.5} flexDirection='column'>
            <Headbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Reports limit={limit} offset={offset} searchTerm={searchTerm} setLimit={setLimit} setOffset={setOffset} setTotal={setTotal} setPage={setPage} />
            {total > 1 && (
                <Box
                    width={1}
                    mt="20px"
                    display="flex"
                    justifyContent="space-between"
                >
                    <IconButton
                        //   onClick={() => handlePageChange(page - 1)}
                        disabled={offset === 0}
                        type="button"
                        sx={{
                            bgcolor: "white",
                            border: "1px solid #D0D5DD",
                            borderRadius: "8px",
                            fontSize: "14px",
                            color: "#344054",
                            fontWeight: "600",
                            px: 1,
                        }}
                        aria-label="Pevious"
                    >
                        <ArrowBack />
                        <Typography fontSize="inherit" fontWeight="inherit" ml={1}>
                            Previous
                        </Typography>
                    </IconButton>

                    <Pagination
                        page={page}
                        //   onChange={(_, value) => handlePageChange(value)}
                        count={10}
                    />
                    <IconButton
                        type="button"
                        //   onClick={() => handlePageChange(page + 1)}
                        disabled={offset + limit >= total}
                        sx={{
                            bgcolor: "white",
                            border: "1px solid #D0D5DD",
                            borderRadius: "8px",
                            fontSize: "14px",
                            color: "#344054",
                            fontWeight: "600",
                            px: 1,
                        }}
                        aria-label="Next"
                    >
                        <Typography fontSize="inherit" fontWeight="inherit" mr={1}>
                            Next
                        </Typography>
                        <ArrowForward />
                    </IconButton>
                </Box>
            )}
        </Box>
    )
}

export default UserReports
