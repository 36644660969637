import React, { useEffect, useState } from 'react'
import { Box, Divider, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material'
import RadialChart from 'components/charts/Radial';
import YearlyReportChart from 'components/charts/YearlyReport';
import MonthlyReportChart from 'components/charts/MonthlyReport';
import { DASHBOARD_EARNING_REPORT } from 'services/constants';
import useApiQuery from 'hooks/useApiQuery';
import Loader from 'components/Loader';
import Error from 'components/Error';

const Earning = ({ duration, totalUsers, paidUsers, freeUsers }) => {
    const [reportDuration, setReportDuration] = useState(duration || 'year');
    const [reportData, setReportData] = useState(null);

    const {
        isLoading,
        error,
        data: apiResponse,
    } = useApiQuery({ queryKey: [DASHBOARD_EARNING_REPORT, reportDuration], url: DASHBOARD_EARNING_REPORT + (reportDuration ? `/?duration=${reportDuration}` : '') });

    const handleChange = (e) => {
        const value = e.target.value;
        setReportDuration(value);
        setReportData(null);
    };

    useEffect(() => {
        setReportDuration(duration)
    }, [duration])

    useEffect(() => {
        if (apiResponse) {
            setReportData(apiResponse.data.reportData);
        }
    }, [apiResponse]);

    if (error) {
        return <Error error={error} />
    }
    if (isLoading) {
        <Loader />
    }



    return (
        <Grid container mt={3} spacing={3} width={1}>
            <Grid item xs={12} width={1} md={8}
            >
                <Box bgcolor="#FFF" height={1} borderRadius="20px" py='20px' px={3}>
                    <Box display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant='h5' fontWeight={500}>Users</Typography>
                        <RadioGroup
                            style={{ display: "flex", flexDirection: "row" }}
                        >
                            <FormControlLabel value="Paid User" control={<Radio />} label="Paid User" />
                            <FormControlLabel value="Free User" control={<Radio />} label="Free User" />
                        </RadioGroup>
                        <Select
                            value={reportDuration}
                            size='small'
                            sx={{ minWidth: 120 }}
                            placeholder='placeholder'
                            onChange={handleChange}
                        >
                            <MenuItem value=''>Select Duration</MenuItem>
                            <MenuItem value='month'>Monthly</MenuItem>
                            <MenuItem value='year'>Yearly</MenuItem>
                        </Select>
                    </Box>
                    <Box mt={2}>
                        <Divider />
                    </Box>
                    <Box mt={2} width={1}>
                        {reportData && (
                            reportDuration === 'month' ? (
                                <MonthlyReportChart data={reportData} />
                            ) : (<YearlyReportChart data={reportData} />)
                        )

                        }
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} width={1} md={4}>
                <Box borderRadius="20px" bgcolor="#FFF" height={1} py='20px' px={3}>
                    <Box display={"flex"}
                        justifyContent="space-between"
                        flexDirection="column"
                        alignItems="center"
                        height="100%"
                    >
                        <Typography variant='h5' alignSelf={"flex-start"} fontWeight={500} marginBottom={"24px"}>Total Earning</Typography>
                        <Box display={"flex"}
                            justifyContent={"center"}
                            alignItems="center" height="100%">
                            <RadialChart paidUsers={paidUsers} freeUsers={freeUsers} totalUsers={totalUsers} />
                        </Box>
                        <Box width={1}>
                            <Divider />
                            <RadioGroup

                                style={{ display: "flex", width: '100%', flexDirection: "row", alignItem: 'center', justifyConten: 'center' }}
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="Active User" control={<Radio />} label="Active User" />
                                <FormControlLabel value="Paid User" control={<Radio />} label="Paid User" />
                            </RadioGroup>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Earning