import React from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  // Search,
  // StyledInputBase,
  // SearchIconWrapper,
  StyledTitleBox,
} from "./style";
import { CustomButton } from "../../../../components";

import { useAuth } from "../../../../hooks/useAuth";
// import ProjectModulesAutocomplete from "./AutoComplete";
import Sidebar from "../Sidebar";
import { StyledBox } from "../Sidebar/style";
import DummyNav from "./DummyNav";
import TopNav from "../Sidebar/TopNav";

export default function PrimarySearchAppBar({ handleDrawerToggle }) {
  const theme = useTheme();
  const { signout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // Handle logout
  const handleLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    signout();
    toast.success("Logout successfully");
    navigate("/login");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";

  const ProfileMenu = () => {
    return (
      <Menu
        sx={{ marginTop: 4 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        {/* Logout Button */}
        {/* Not show on mobile view in menu item */}
        {!mobileMoreAnchorEl && (
          <MenuItem>
            <CustomButton loading={null} onClick={handleLogout}>
              Logout
            </CustomButton>
          </MenuItem>
        )}
      </Menu>
    )
  }

  // Mobile menu
  const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <Box display='flex' justifyContent='space-between' width={1}>
      <Box width={1} display='flex' flex={1} justifyContent='center'>
        <AppBar
          position="static"
          sx={{ backgroundColor: theme.palette.common.white, width: 1, p: 0 }}

        >
          <Box maxWidth={1400} width={1} mx='auto'>
            <Toolbar sx={{ color: theme.palette.common.black }}>
              {/* Toggle menu icon */}
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              {/* <Sidebar>
                <ProfileMenu />
              </Sidebar> */}
              <TopNav>
                <ProfileMenu />
              </TopNav>

              {/* <DummyNav /> */}
              {/* Search input */}
              {/* <Box width={1} flexGrow={1}>
            <Sidebar />
            {/* <ProjectModulesAutocomplete /> */}
              {/* <Search>
              <SearchIconWrapper>
                <img src={`/icons/search-icon.svg`} alt="Search" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="What are you looking for"
                inputProps={{ "aria-label": "search" }}
              />
            </Search> */}
              {/* </Box> */}
              {/* Right nav item box */}
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {/* Notification icon */}
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={1} color="error">
                    <img src={`/icons/bell.svg`} alt="Notification" />
                  </Badge>
                </IconButton>
                {/* User icon */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  color="inherit"
                >
                  <img src={`/icons/group.svg`} alt="User" />
                </IconButton>
                {/* Title Box */}
                <StyledTitleBox>
                  <Typography variant="subtitle2">{"Admin"}</Typography>
                </StyledTitleBox>
                {/* More menu icon */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <img src={`/icons/create-down.svg`} alt="Menu" />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <img src={`/icons/dots-vertical.svg`} alt="More" />
                </IconButton>
              </Box>
            </Toolbar>
          </Box>
        </AppBar>
      </Box>
      {/* {renderMobileMenu} */}
      {/* <Box>
        {renderMenu}
      </Box> */}
    </Box>
  );
}
