import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";

const SingleReport = ({ reportedBy, reportedUser, reason = '', type = 'message', reportCategory = [] }) => {

    const { firstName: reporterFName = '', lastName: reporterLName = '' } = reportedBy || {};
    const { firstName = '', lastName = '' } = reportedUser || {};
    return (
        <Box borderRadius="10px"
            bgcolor="white"
            boxShadow="0px 0px 16px 0px #2632381F" p={2}>
            <Grid justifyContent='space-between' container>
                <Grid item display='flex' gap='12px'>
                    <Box component='img' width={44} height={44} src="/icons/group.svg" alt={firstName} />
                    <div>
                        <Typography variant="subtitle1" fontWeight={400} color='#84818A' fontSize='10px'>Reported User:</Typography>
                        <Typography variant="subtitle2" color='#1D2939' mt={1} fontWeight={600}>{firstName} {lastName}</Typography>
                    </div>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" fontWeight={400} color='#84818A' fontSize='10px'>Reported Category:</Typography>
                    <Box display='flex' gap='12px'>
                        <Typography variant="subtitle2" color='#1D2939' mt={1} fontWeight={600}>{reason}</Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" fontWeight={400} color='#84818A' fontSize='10px'>Report Time:</Typography>
                    <Typography variant="subtitle2" color='#1D2939' mt={1} fontWeight={600}>{moment().format('MM-DD-YYYY')}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" fontWeight={400} color='#84818A' fontSize='10px'>Type:</Typography>
                    <Typography variant="subtitle2" textTransform='capitalize' color='#1D2939' mt={1} fontWeight={600}>{type}</Typography>
                </Grid>
                <Grid item >
                    <Typography variant="subtitle1" fontWeight={400} color='#84818A' fontSize='10px'>Reported by:</Typography>
                    <Box mt={1} display='flex' gap='12px' alignItems='center'>
                        <Box component='img' width={24} height={24} src="/icons/group.svg" alt={reporterFName} />
                        <Typography variant="subtitle2" color='#1D2939' fontWeight={600}>{reporterFName} {reporterLName}</Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Typography variant="subtitle1" fontWeight={400} color='#84818A' fontSize='10px'>Reason</Typography>
                        <Typography variant="subtitle2" color='#1D2939' mt={1} fontWeight={600} >{reportCategory.join(',')}</Typography>
                    </Box>

                </Grid>

            </Grid>

        </Box>
    )
}

export default SingleReport
