import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";

export const StyledCard = styled(Card)(({ theme, bgColor }) => ({
  borderRadius: theme.spacing(1),
  height: "147px",
  padding: 12,
  background: bgColor,

}));
