import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ConfirmDialog } from "components";
import CustomDialog from "components/Modal";
import CreatePromotion from "./CreatePromotion";
import UpdateStatusDialog from "components/StatusDialog/StatusDialog";
import useApiMutation from "hooks/useApiMutation";
import moment from "moment";
import { useQueryClient } from "react-query";
import { PROMOTION } from "services/constants";

const SinglePromotion = (promotionData) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useApiMutation();

  const { title = '', startDate, endDate, type, discount } = promotionData || {};
  const fetchPromoList = () => queryClient.invalidateQueries({ queryKey: 'promotions' });

  const handleDelete = () => {
    mutate({ method: 'delete', url: PROMOTION + promotionData._id }, {
      onSuccess: () => {
        setOpenDeleteModal(false);
        fetchPromoList();
      }
    });
  };

  const updateStatusHandler = (status) => {
    mutate({ url: PROMOTION + promotionData._id, data: { status } });
  };

  return (
    <>
      <ConfirmDialog
        title="Delete Promotion"
        dialogContext="Are you sure to delete promotion ?"
        open={openDeleteModal}
        isLoading={isLoading}
        setOpen={setOpenDeleteModal}
        onConfirm={handleDelete}
      />
      <CustomDialog
        title="Update Promotion"
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
      >
        <CreatePromotion promotionData={promotionData} setOpen={setOpenUpdateModal} />
      </CustomDialog>
      <UpdateStatusDialog
        open={openStatusModal}
        onClose={() => setOpenStatusModal(false)}
        onUpdate={updateStatusHandler}
        isLoading={false}
        status={'inactive'}
      />
      <Box
        gap={3}
        p={2}
        borderRadius="10px"
        bgcolor="white"
        boxShadow="0px 0px 34px 0px #2632381F"
        height="100%"
        position="relative"
      >
        <Box display="flex" gap="12px" alignItems="center">
          <Box
            p="14px"
            flexShrink={1}
            component="img"
            borderRadius="50%"
            bgcolor="#F4F7FA"
            src="/icons/tag.svg"
            alt="tag"
          />
          <Box
            flexGrow={1}
            display="flex"
            flexWrap="wrap"
            alignItems="flex-start"
            justifyContent='space-between'
            gap="20px"
          >
            <Box>
              <Typography variant="body1" fontSize="10px" color="#858688">
                Promotion title:
              </Typography>
              <Typography
                variant="body2"
                mt={1}
                color="#222222"
                fontSize="14px"
                fontWeight={600}
              >
                {title}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" fontSize="10px" color="#858688">
                Discount:
              </Typography>
              <Typography
                variant="body2"
                mt={1}
                color="#222222"
                fontSize="14px"
                fontWeight={600}
              >
                {discount} {type === 'percentage' && '%'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" fontSize="10px" color="#858688">
                Start Date:
              </Typography>
              <Typography
                variant="body2"
                mt={1}
                color="#222222"
                fontSize="14px"
                fontWeight={600}
              >
                {moment(startDate).format('MM-DD-YYYY')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" fontSize="10px" color="#858688">
                Expiry Date:
              </Typography>
              <Typography
                variant="body2"
                mt={1}
                color="#222222"
                fontSize="14px"
                fontWeight={600}
              >
                {moment(endDate).format('MM-DD-YYYY')}
              </Typography>
            </Box>
            <Box

              display="flex"
              alignItems="center"
              gap="12px"
              mr="12px"
            >
              <Box
                component="img"
                height="20px"
                width="20px"
                color="red"
                src="/icons/edit.svg"
                onClick={() => setOpenUpdateModal(true)}
                alt="edit"
              />
              <Box
                component="img"
                height="20px"
                width="20px"
                onClick={() => setOpenDeleteModal(true)}
                src="/icons/trash.svg"
                alt="trash"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SinglePromotion;
