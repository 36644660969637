import React from "react";
import Events from "./components/Events";
import AddEvent from "./components/AddEvent";

const EventsModule = () => {

  return (
    <>
      <AddEvent />
      <Events />
    </>
  );
};

export default EventsModule;
