import React from 'react'
import { Box, Button, Divider, Grid, LinearProgress, Typography } from '@mui/material'
import DropDown from './Dropdown'

const WorldWide = () => {

    const countiresData = [{
        name: 'America',
        percent: '200'
    }, {
        name: 'Netherlands',
        percent: '200'
    }, {
        name: 'France',
        percent: '30'
    }, {
        name: 'Spain',
        percent: '40'
    }, {
        name: 'India',
        percent: '50'
    }, {
        name: 'Indonesia',
        percent: '60'
    }, {
        name: 'Romania',
        percent: '85'
    }];

    const worldwideCountiresData = [{
        name: 'United States',
        count: '200'
    }, {
        name: 'Netherlands',
        count: '200'
    }, {
        name: 'France',
        count: '205'
    }, {
        name: 'Spain',
        count: '450'
    }, {
        name: 'India',
        count: '502'
    }];



    return (
        <Grid container mt={3} spacing={3}>
            <Grid md={8} item alignItems={"center"}
            >
                <Box bgcolor="#FFF" borderRadius="20px" height={1} padding="20px">
                    <Typography variant='h5' fontWeight={500}>User</Typography>
                    <Box marginTop={"20px"} display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap='wrap'

                    >
                        {
                            worldwideCountiresData.map(({ name, count }, i) => (
                                <Box display="flex"
                                    justifyContent="space-between"
                                    flexDirection="column"
                                    gap={1}
                                    key={i}
                                >

                                    <Box display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        gap={"8px"}

                                    >
                                        <span style={{ width: "20px", height: "20px", display: "inline-block", borderRadius: "50%", background: "black" }}>
                                        </span>
                                        <Typography variant="body1" color="#858688" >
                                            {name}
                                        </Typography>
                                    </Box>
                                    <Typography variant='h3' fontWeight={600}>{count}</Typography>
                                </Box>
                            ))}
                    </Box>
                    <img src={`/images/mapImage.png`} alt="upload" />
                </Box>
            </Grid>
            <Grid item md={4}
                display="flex"
                flexDirection="column"
            >
                <Box borderRadius="20px" bgcolor="#FFF" padding="20px">
                    <Box mb={2} display={"flex"}
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}>
                        <Typography variant='h5' fontWeight={500}>Users from Countries</Typography>
                        <DropDown />
                    </Box>
                    <Divider />
                    {
                        countiresData.map(({ name, percent }, i) => (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                flexDirection="column"
                                gap={3}
                                key={i}
                                marginTop={"20px"}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography variant='P' fontWeight={500}>{name}</Typography>
                                    <Button style={{ background: " #5655D729", borderRadius: "90px", padding: "4px" }}>{percent}%</Button>
                                </Box>
                                <LinearProgress variant="determinate" value={20} style={{ height: "12px", borderRadius: "90px" }} />
                            </Box>
                        ))
                    }

                </Box>

            </Grid>
        </Grid>
    )
}

export default WorldWide