import React from "react";
import { Box, Grid } from "@mui/material";
import SinglePractice from "../components/SinglePractice";
import { PRACTICE } from "services/constants";
import useApiQuery from "hooks/useApiQuery";
import NoData from "components/NoData";
import Loader from "components/Loader";
import Error from "components/Error";

const Practices = ({ searchTerm }) => {

    const {
        isLoading,
        error,
        data: apiResponse,
    } = useApiQuery({ queryKey: ["practices", searchTerm], url: PRACTICE + `list?searchTerm=${searchTerm}` });

    if (isLoading) return <Loader />;
    if (error) return <Error error={error} />

    return <Box mt={3}>
        <Grid container spacing={2}>
            {apiResponse &&
                (apiResponse.data.practices.length > 0 ? (
                    apiResponse.data.practices.map((practiceData) => (
                        <Grid width={1} key={practiceData._id} item md={6} lg={4}>
                            <SinglePractice {...practiceData} />
                        </Grid>
                    ))
                ) : (
                    <NoData />
                ))}
        </Grid>
    </Box>
};

export default Practices;
