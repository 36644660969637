import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ConfirmDialog } from "components";
import CustomDialog from "components/Modal";
import UpdateStatusDialog from "components/StatusDialog/StatusDialog";
import useApiMutation from "hooks/useApiMutation";
import { useQueryClient } from "react-query";
import { EVENT } from "services/constants";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CreateEvent from "./CreateEvent";

const SingleEvent = (eventData) => {
  const { _id, eventName, date, time, description, reservations } = eventData;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useApiMutation();

  const fetchEventList = () => queryClient.invalidateQueries({ queryKey: 'events' });

  const handleDelete = () => {
    mutate({ method: 'delete', url: EVENT + `/${_id}` }, {
      onSuccess: () => {
        setOpenDeleteModal(false);
        fetchEventList();
      }
    });
  };

  const updateStatusHandler = (status) => {
    mutate({ method: 'patch', url: EVENT, data: { status } });
  };

  // today date in moment format
  const today = moment().startOf('day');

  const checkEventStatus = () => {
    const formattedData = moment(date);


    if (formattedData.isSame(today, 'day')) {
      return 'today'
    } else if (formattedData.isAfter(today)) {
      return 'upcoming'
    } else {
      return 'completed'
    }
  }

  const eventStatus = checkEventStatus();
  const daysLeft = moment(date).diff(moment(today), 'days');
  return (
    <>
      <ConfirmDialog
        title="Delete Event"
        dialogContext="Are you sure to delete event ?"
        open={openDeleteModal}
        isLoading={isLoading}
        setOpen={setOpenDeleteModal}
        onConfirm={handleDelete}
      />
      <CustomDialog
        title="Update Event"
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
      >
        <CreateEvent eventData={eventData} setOpen={setOpenUpdateModal} />
      </CustomDialog>
      <UpdateStatusDialog
        open={openStatusModal}
        onClose={() => setOpenStatusModal(false)}
        onUpdate={updateStatusHandler}
        isLoading={false}
        status='inactive'
      />
      <Box
        gap={3}
        py={2}
        px={3}
        borderRadius="10px"
        bgcolor="white"
        position='relative'
        className='cursor-pointer'
        boxShadow="0px 0px 34px 0px #2632381F"
        height="100%"
        onClick={() => navigate(`/event/${_id}`)}
      >
        <Box display='flex' gap='6px' alignItems='center'>
          <Typography
            variant="body2"
            color="#222222"
            fontSize="16px"
            fontWeight={500}
          >
            {eventName}
          </Typography>
          {
            eventStatus === 'today' ? (<Box component='button' sx={{ bgcolor: '#E5CB33', py: 0.5, px: 1, border: 'none', fontSize: '10px', display: 'flex', justifyContent: 'center', alignItems: "center", color: '#222222', fontWeight: 400, borderRadius: '17px' }} size="small" variant="outlined">Today</Box>) : eventStatus === 'upcoming' ? (
              <Box component='button' sx={{ bgcolor: '#EAECEE', py: 0.5, px: 1, border: 'none', fontSize: '10px', display: 'flex', justifyContent: 'center', alignItems: "center", color: '#222222', fontWeight: 400, borderRadius: '17px' }} size="small" variant="outlined">Upcoming</Box>
            ) : (
              <Box component='button' sx={{ bgcolor: '#2E8852', py: 0.5, px: 1, border: 'none', fontSize: '10px', display: 'flex', justifyContent: 'center', alignItems: "center", color: 'white', fontWeight: 400, borderRadius: '17px' }} size="small" variant="outlined">Completed</Box>
            )
          }
        </Box>
        <Box display='flex' mt='6px' gap='5px' alignItems='center'>
          <Typography
            variant="body2"
            color="#222222"
            fontSize="16px"
            fontWeight={600}
          >
            Date:
          </Typography>
          <Typography
            variant="body2"
            color="#222222"
            fontSize="14px"
            fontWeight={400}
          >
            {moment(date).format('DD-MM-YYYY')}
          </Typography>
        </Box>

        <Box display='flex' gap='5px' alignItems='center'>
          <Typography
            variant="body2"
            color="#222222"
            fontSize="16px"
            fontWeight={600}
          >
            Time:
          </Typography>
          <Typography
            variant="body2"
            color="#222222"
            fontSize="14px"
            fontWeight={400}
          >
            {moment(time, 'hh:mm').format('LT')}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          mt={2}
          color="#858688"
          fontSize="14px"
          fontWeight={400}
        >
          {description}
        </Typography>
        <Box mt={2} display='flex' gap={2}>
          <img src="/icons/reservation.svg" alt="reservation" />
          <Typography
            variant="body2"
            color="#222222"
            fontSize="12px"
            fontWeight={600}
          >
            {reservations} Reservations
          </Typography>
        </Box>

        {/* <Button fullWidth sx={{
          bgcolor: '#222222', mt: 2, color: 'white', fontWeight: 500, borderRadius: '20px', ':hover': {
            background: '#222222'
          }
        }} size="large" variant="outlined">Join Event</Button> */}
        {
          daysLeft > 0 && (
            <Button fullWidth sx={{
              display: 'flex', alignItems: 'center',
              bgcolor: '#E2E4EA', gap: 0.8, mt: 2, border: 'none', color: '#222222', fontWeight: 500, borderRadius: '20px', ':hover': {
                background: '#E2E4EA', border: 'none'
              }
            }} size="large" variant="outlined"> <Box mb={0.5} display='block' component='img' src="/icons/clock.svg" alt="clock" /> {daysLeft} Days Left</Button>
          )
        }

        <Box
          display="flex"
          alignItems="center"
          gap="12px"
          mr="12px"
          position='absolute'
          right={10}
          top={10}
        >
          <Box
            component="img"
            height="20px"
            width="20px"
            color="red"
            src="/icons/edit.svg"
            onClick={(e) => {
              e.stopPropagation();
              setOpenUpdateModal(true)
            }}
            alt="edit"
          />
          <Box
            component="img"
            height="20px"
            width="20px"
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteModal(true);
            }}
            src="/icons/trash.svg"
            alt="trash"
          />
        </Box>
      </Box>
    </>
  );
};

export default SingleEvent;
