import React, { useState } from "react";
import AddPromotion from "./components/AddPromotion";
import Promotions from "./components/Promotions";

const PromotionModule = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div>
      <AddPromotion searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <Promotions searchTerm={searchTerm} />
    </div>
  );
};

export default PromotionModule;
